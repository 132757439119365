import React from 'react';
import styled from 'styled-components';

export default ({ getTranslation, isHeaderLogo = false }) => {
  const Img = styled.img`
    @media only screen and (min-width: 768px) {
      margin-left: ${isHeaderLogo ? '140' : '0'}px;
    }
    margin-left: ${isHeaderLogo ? '20' : '0'}px;
  `;
  const { body, variables } = getTranslation('RESET_PASSWORD_PARTNER_LOGO') || {
    body: '',
    variables: undefined,
  };
  const { width, height } = defineDimensions(variables);
  return <Img src={body} alt="partner_logo" width={width} height={height} />;
};

const defineDimensions = (variables) => {
  let width = '100px';
  let height = '55px';
  if (variables) {
    const widthFromVariables = variables['$logoPasswordUiWidth'];
    const heightFromVariables = variables['$logoPasswordUiHeight'];

    width = widthFromVariables ? widthFromVariables : width;
    height = heightFromVariables ? heightFromVariables : height;
  }

  return { width, height };
};
