import { Input } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

export const Error = styled.span`
  display: block;
  color: #f25652;
  font-size: 14px;
  position: absolute;
  z-index: 1000;
  bottom: -23px;
`;

export const FormInput = styled(Input)`
  width: 100%;
  max-width: 400px;
  height: 40px;
  &:focus {
    border-color: #532d62 !important;
  }
  font-family: ${(props) => {
    return props.fontFamily ? props.fontFamily + ' !important' : 'inherit';
  }};
`;

export const FormField = styled.div`
  position: relative;
  margin-bottom: 34px;
`;

export const SubmitButton = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 0;
`;

export const Spinner = styled(Icon)`
  position: absolute;
  color: white;
  left: 16px;
  top: 13px;
`;

export const Label = styled.label`
  color: #532d62;
  font-size: 14px;
  margin-bottom: 7px;
  display: block;
  width: 100%;
`;

export const Wrapper = styled.div`
  margin-top: 42px;
`;

export const ButtonContainer = styled.div`
  margin-top: 45px;
`;
