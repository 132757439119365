import React, { Component } from 'react';
import { StyledHeader } from '../styles';
import styled from 'styled-components';

const Img = styled.img`
  @media only screen and (min-width: 768px) {
    margin-left: 140px;
  }
  margin-left: 10px;
`;

class HomelyHeader extends Component {
  render() {
    const { body } = this.props.getTranslation('RESET_PASSWORD_PARTNER_LOGO') || {
      body: '',
      variables: undefined,
    };
    return (
      <StyledHeader background="#0f6f5c" boxShadow="none" marginTop="0">
        <Img src={body} alt="partner_logo" width="170px" height="43px" isHeaderLogo={true} />
      </StyledHeader>
    );
  }
}

export default HomelyHeader;
